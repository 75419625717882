import { Trans } from "react-i18next";
import { NotificationHelper } from "../components/shared";

export const Errors = {
  OTP_EXPIRED: "error.otpExpired",
  UNEXPECTED_ERROR: "error.unexpectedError",
  TOO_MANY_REQUEST: "error.tooManyRequest",
  ACCOUNT_BLOCKED: "error.accountBlocked",
  OTP_NO_MATCH: "error.otpNotMatch"
};

export const handleExhaustedTimeSlotError = (e = {}) => {
  if (!e.response) return false;

  if (e.response.data?.response === "error.slotInvalidOrOccupied") {
    NotificationHelper.error(<Trans i18nKey="error.slotInvalidOrOccupied" />);
    return true;
  }

  return false;
};

export const handleFileSizeError = (e = {}) => {
  if (
    (e.response &&
      e.response.status === 500 &&
      e.response.data?.error?.code === "LIMIT_FILE_SIZE") ||
    e.response.data?.response === "error.invalidFileSize"
  )
    return NotificationHelper.error(<Trans i18nKey="error.invalidFileSize" />);
};
