import { autohubLive, baseURL, domainOrigin } from "../util/constants";

export const productionWebLink = autohubLive;

// APIs base url
export const BaseURL = baseURL[domainOrigin];

// API ROUTE VARAIABLE
export const API = "/api";

// GLOBAL
export const API_GET_AUTOHUB_LOCALIZATIONS = `${API}/localization/autohub`;
export const API_GET_PUBLISHED_ROUTES = `${API}/pages`;
export const API_VERIFY_WEB_PASSWORD = `${API}/validate-web-password`;
export const API_GET_MEDIA = `${API}/media`;
export const GET_MEDIA_URL = `${BaseURL + API}/util/image?key=`;
export const GET_VIDEO_URL = `${BaseURL + API}/video-streaming?key=`;
export const API_GET_SETTINGS = `${API}/user/settings`;
export const API_UPLOAD_MEDIA = `${API}/util/upload`;

// HOME API
export const API_GET_BANNERS = `${API}/slider`;
export const API_GET_HEADLINE = `${API}/headline`;

// USER API
export const API_RIGESTER_USER = `${API}/user-register`;
export const API_UPDATE_USER = `${API}/update-user`;
export const API_VERIFY_USER = `${API}/user-verify`;
export const API_ADD_USER_CAR = `${API}/add-user-car`;
export const API_LOGOUT_USER = `${API}/user/logout`;
export const API_UPDATE_USER_CAR = `${API}/update-user-car`;
export const API_DELETE_USER_CAR = `${API}/delete-user-car`;
export const API_UPDATE_USER_CONTACT_SETTINGS = `${API}/update-contact-preference`;
export const API_USER_NOTIFICATIONS = `${API}/user-notifications`;
export const API_DELETE_ACCOUNT = `${API}/delete-account`;
export const API_GENERATE_CAREEM_CODE = `${API}/generate-careem-code`;

// APPOINTMENT API
export const API_CREATE_SCHEDULE_APPOINTMENT = `${API}/schedule-appointment`;
export const API_GET_APPOINTMENTS = `${API}/schedule-appointment/user-appointments`;
export const API_CREATE_RESCHEDULE_APPOINTMENT = `${API}/re-schedule-appointment`;
export const API_CANCEL_APPOINTMENT = `${API}/cancel-appointment`;
export const API_GET_CITIES = `${API}/city`;
export const API_GET_CAR_BRANDS = `${API}/brand`;
export const API_GET_CAR_MODELS = `${API}/model`;
export const API_GET_CAR_YEARS = `${API}/year`;
export const API_GET_OCASSIONS = `${API}/ocassions/`;
export const API_GET_SERVICE_BY_ID = `${API}/service`;
export const API_GET_SERVICE_PROVIDERS_BY_SERVICES = `${API}/services/branches`;
export const API_GET_AUTOHUB_SERVICE_CATEGORIES = `${API}/service-categories`;
export const API_GET_AUTOHUB_SERVICES = `${API}/services-for-booking`;
export const API_GET_RELATED_SERVICES = `${API}/related-services`;
export const API_GET_SLOTS = `${API}/branches/get-slots-by-date`;
export const API_RECENT_CARS = `${API}/recent-cars/`;
export const API_GET_APPOINTMENT_DAYS = `${API}/branch/current-month-slots`;
export const API_VALIDATE_APPOINTMENT_CAR = `${API}/appointment/validate-car`;
export const API_UPDATE_SEEN_STATUS = `${API}/appointment/notifications-status`;
export const API_GET_ACTIVE_BRANCHES = `${API}/dashboard-branches`;
export const API_VERIFY_BRANCH_CODE = `${API}/fast-drop/verify-branch-code`;
export const API_CREATE_COLLECTION_APPOINTMENT = `${API}/appointment/collection-appointment`;
export const API_GET_APPOINTMENT_BY_APPOINTMENTHASH = `${API}/appointment/hash`;

// REQUIRED DOCUMENTS
export const API_GET_REQUIRED_DOCUMENTS = `${API}/web/required-documents`;
export const API_GET_CAR_OWN_BY = `${API}/car-own-by/service`;
export const API_GET_BOOKING_APPOINTMENT_FOR = `${API}/web/booking-appointment-for`;
export const API_UPDATE_REQUIRED_DOCUMENTS = `${API}/appointment/required-documents`;

// OFFERS API
export const API_GET_OFFERS = `${API}/get-offers`;
export const API_GET_CATEGORIZED_OFFERS = `${API}/get-categorized-offers`;
export const API_GET_OFFER_FOR_SERVICE = `${API}/offers-for-service`;

// LOCATIONS API
export const API_GET_BRANCHES_ACC_TO_LOCATIONS = `${API}/get-located-branches`;

// KAIZEN API
export const API_GET_KAIZENS = `${API}/kaizens`;
export const API_GET_KAIZEN_BY_ID = `${API}/kaizen`;

// TRACK YOUR PROGRESS API
export const API_TRACK_PROGRESS = `${API}/track`;
export const API_CND_APPOINTMENT_PROGRESS = `${API}/cnd-track`;
export const API_UPDATE_FAST_COLLECT_SEEN_STATUS = `${API}/fast-collect/seen-notifications`;
export const API_UPDATE_RECOMMENDED_OFFER_SEEN_STATUS = `${API}/recommendation-offer/seen-status`;
export const API_CUSTOMER_NOT_SATISFIED = `${API}/fast-collect/customer-not-satisfied`;
export const API_GET_AVAILABLE_SLOTS_FOR_CAR_PICKUP = `${API}/fast-collect/available-slots-for-car-pickup`;
export const API_SCHEDULE_CAR_PICKUP = `${API}/fast-collect/schedule-car-pickup`;

// ESTIMATION REQUEST API
export const API_POST_ESTIMATION_REQUEST = `${API}/estimation-request`;
export const API_GET_ESTIMATION_REQUEST = `${API}/user-estimation-request`;
export const API_GET_ESTIMATION_REQUEST_BY_ID = `${API}/estimation-request`;
export const API_VALIDATE_ESTIMATION_CAR = `${API}/estimation/validate/car`;
export const API_UPDATE_ESTIMATION_REQUEST_COMMENTS = `${API}/estimation/comments`;

// ADDONS API
export const API_ADDON_OFFERS = `${API}/addon-offers`;
export const API_ADDON_VARIANTS = `${API}/addon-variants`;
export const API_ADDON_ORDER = `${API}/order`;
export const API_POST_PROMO = `${API}/promo-verify`;

// E-SHOP APIS
export const API_GET_CATEGORIES = `${API}/eshop-categories`;
export const API_GET_VARIANTS = `${API}/eshop-variants`;

// PAYMENT SERVICE
export const API_PAY = `${API}/pay`;
export const API_VERIFY_PAY = `${API}/verify-pay`;
export const API_TAMARA_PAY = `${API}/tamara-verify`;

// USER ORDERS
export const API_GET_ORDERS = `${API}/orders`;
export const API_GET_ORDER_BY_ID = `${API}/order`;
export const API_DOWNLOAD_FINAL_INVOICE = (id, type) => `${API}/delivery/${id}/invoice/${type}`;
export const API_DOWNLOAD_DEPOSIT_INVOICE = (id, type) => `${API}/order/${id}/invoice/${type}`;
export const API_REFUND_ORDER = `${API}/refund`;
export const API_REQUEST_REFUND_ORDER = `${API}/request-refund-order`;
export const API_REFUND_ORDER_INVOICE = (id, type) => `${API}/order/${id}/refund/${type}`;

// CONTACT API
export const API_CREATE_CONTACT_US_REQUEST = `${API}/contactus/v2`;

// BLOGS API
export const API_GET_BLOG_TAGS = `${API}/tags`;
export const API_GET_BLOGS = `${API}/blogs`;
export const API_GET_RELATED_BLOGS = `${API}/blogs/related`;

// QUOTATIONS API
export const API_GET_QUOTATIONS = `${API}/quotations`;
export const API_REQUEST_QUOTE = `${API}/request-quote`;
export const API_GET_REQUESTED_QUOTES = `${API}/requested-quotes`;
export const API_GET_REQUESTED_QUOTE_BY_ID = `${API}/requested-quote/`;

// SUBSCRIPTION API
export const API_GET_SUBSCRIPTION = `${API}/subscription`;
export const API_SUBSCRIBE_OFFER = `${API}/subscribe-offer`;
export const API_GET_SUBSCRIBED_OFFERS = `${API}/subscribed-offers`;
export const API_GET_SUBSCRIBED_OFFER = `${API}/subscribed-offer/`;
export const API_GET_USER_SUBSCRIBED_OFFER = `${API}/user-subscribed-offer/`;
export const API_GET_ALL_SUBSCRIPTIONS = `${API}/subscriptions`;
export const API_GET_SUBSCRIPTION_DETAILS = `${API}/subscription/slug`;

