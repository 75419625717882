import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, getFormatedDate, truncateText } from "../../util/common";
import { Button, AddonsCart, AddonDetailsModal, Icon } from "./";
import { doExist, handleAddDeleteItem } from "../../util/addonsUtils";

const AddonsCartsList = (props) => {
  const { type, addons = {}, cart, setCart, goForward, setLoader } = props;

  const { t } = useTranslation();
  const [selectedAddon, setSelectedAddon] = useState({});
  const [detailsModal, setDetailsModal] = useState(false);

  const lang = currentLanguage();

  const openDetailsModal = (addon, type) => {
    setLoader(true);
    setSelectedAddon({ addon, type });
    setDetailsModal(true);
  };
  const closeDetailsModal = () => setDetailsModal(false);

  const handleCart = (item, type) => handleAddDeleteItem({ item, type, cart, setCart, t });
  const checkExist = (item, type) => doExist({ item, type, cart });

  const quantity = cart.offers.length + cart.products.length;
  const cardClass = `col-md-6 col-lg-4 ${type === "appointment" && "col-xl-3"}`;

  return (
    <>
      {addons.recommendations?.data?.length ? (
        <div className="card-listing">
          <h4>{t("addons.recommendedOffers")}</h4>
          <div className="row">
            {addons.recommendations?.data?.map((offer, key) => {
              if (!offer) return;
              return (
                <div className={cardClass} key={key}>
                  <div className="single-product">
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${
                          GET_MEDIA_URL +
                          `${
                            offer?.images?.length &&
                            offer?.images.filter((image) => image.key === "imageOne")[0].value
                          }`
                        })`
                      }}
                    />
                    <div className="meta-data">
                      <h5>{truncateText(offer?.name["value_" + lang], 20)}</h5>
                      <h6>
                        {offer?.price} {t("addons.currency")}
                      </h6>
                      <div className="addon-actions">
                        <Button
                          type="small-roundedLeft"
                          action={() => openDetailsModal(offer, "recommendedOffers")}>
                          {t("addons.seeMoreBtn")}
                        </Button>
                        <Button
                          type="blue-roundedRight"
                          action={() => handleCart(offer, "offers")}
                          customize={checkExist(offer, "offers").exist && "remove"}>
                          {checkExist(offer, "offers").exist
                            ? t("addons.removeFromCart")
                            : t("addons.addToCart")}
                          <Icon iconName="cart" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {addons.recommendations?.hasMore && (
            <span className="view-more-btn" onClick={addons.recommendations?.getMore}>
              {t("addons.viewMoreAddonsBtn")}
            </span>
          )}
        </div>
      ) : null}
      {addons.offers?.data?.length ? (
        <div className="card-listing">
          <h4>{t("addons.addonOffers")}</h4>
          <div className="row">
            {addons.offers?.data?.map((offer, key) => {
              if (!offer) return;
              return (
                <div className={cardClass} key={key}>
                  {offer?.isCampaignActive && (
                    <>
                      <div className="expiry-date addon-expiry ">
                        <span>
                          {t("services.validity")}:{" "}
                          {getFormatedDate(offer?.campaignEndDate, "YYYY-MM-DD")}
                        </span>
                      </div>

                      <div className="ribbon-wrapper addon-ribbon">
                        <div className="ribbon-tag">{t("services.ribbonText")}</div>
                      </div>
                    </>
                  )}
                  <div className="single-product">
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${GET_MEDIA_URL + offer["image_" + lang]})`
                      }}
                    />

                    <div className="meta-data">
                      <h5>{truncateText(offer.name["value_" + lang], 23)}</h5>
                      <h6>
                        {offer.isCampaignActive ? (
                          <>
                            <s>{offer?.price}</s> {offer?.campaignPrice}
                          </>
                        ) : (
                          offer?.price
                        )}{" "}
                        {t("addons.currency")}
                      </h6>
                      <div className="addon-actions">
                        {/* <Button
                          type="small-roundedLeft"
                          action={() => openDetailsModal(offer, "offers")}>
                          {t("addons.seeMoreBtn")}
                        </Button> */}
                        <Button
                          type="blue-roundedRight"
                          action={() => handleCart(offer, "offers")}
                          customize={checkExist(offer, "offers").exist && "remove"}>
                          {checkExist(offer, "offers").exist
                            ? t("addons.removeFromCart")
                            : t("addons.addToCart")}
                          <Icon iconName="cart" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {addons.offers?.hasMore && (
            <span className="view-more-btn" onClick={addons.offers?.getMore}>
              {t("addons.viewMoreAddonsBtn")}
            </span>
          )}
        </div>
      ) : null}

      {addons.variants?.data?.length ? (
        <div className="card-listing">
          <h4>{t("addons.addonProducts")}</h4>
          <div className="row">
            {addons.variants?.data?.map((variant, key) => {
              const availability = variant?.advanceBookingDays;
              const quantity = variant?.totalRemainingQuantity;
              return (
                <div className={cardClass} key={key}>
                  <div className="single-product">
                    <div
                      className="thumbnail"
                      style={{
                        backgroundImage: `url(${GET_MEDIA_URL + variant.image})`
                      }}
                    />
                    <div className="meta-data">
                      <h5>{truncateText(variant.name["value_" + lang], 23)}</h5>
                      <span>{variant.product["value_" + lang]}</span>
                      <h6>
                        {variant.price} {t("addons.currency")}
                      </h6>
                      {quantity > 0 ? (
                        availability ? (
                          <p>
                            {t("addons.availableIn")} {availability} {t("addons.days")}
                          </p>
                        ) : (
                          <p>{t("addons.availableNow")}</p>
                        )
                      ) : (
                        <p className="not-available">{t("addons.notAvailable")}</p>
                      )}
                      <div className="addon-actions">
                        <Button
                          type="small-roundedLeft"
                          action={() => openDetailsModal(variant, "products")}>
                          {t("addons.seeMoreBtn")}
                        </Button>
                        <Button
                          type="blue-roundedRight"
                          action={() => handleCart(variant, "products")}
                          customize={`${quantity <= 0 ? "out-of-stock-btn" : ""} ${
                            checkExist(variant, "products").exist && "remove"
                          }`}
                          disabled={quantity <= 0}>
                          {checkExist(variant, "products").exist
                            ? t("addons.removeFromCart")
                            : quantity <= 0
                              ? t("shop.outOfStock")
                              : t("addons.addToCart")}

                          <Icon iconName={quantity <= 0 ? "exclaim" : "cart"} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {addons.variants?.hasMore && (
            <span className="view-more-btn" onClick={addons.variants?.getMore}>
              {t("addons.viewMoreAddonsBtn")}
            </span>
          )}
        </div>
      ) : null}

      <AddonsCart
        handleCart={handleCart}
        cart={cart}
        setCart={setCart}
        quantity={quantity}
        goForward={goForward}
      />

      <AddonDetailsModal
        show={detailsModal}
        close={closeDetailsModal}
        selected={selectedAddon}
        handleCart={handleCart}
        doExist={checkExist}
        setLoader={setLoader}
      />
    </>
  );
};

export default React.memo(AddonsCartsList);
