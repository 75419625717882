import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faXing,
  faTiktok,
  faSnapchat
} from "@fortawesome/free-brands-svg-icons";
import {
  faSignOut,
  faBell,
  faPencil,
  faTrash,
  faPhone,
  faMapMarkerAlt,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faClock,
  faUser,
  faCar,
  faShoppingCart,
  faMap,
  faPlus,
  faCheck,
  faMinus,
  faDownload,
  faCaretDown,
  faBars,
  faExclamation,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faHourglass3,
  faMedal,
  faCircle,
  faStar,
  faSpinner,
  faMoneyBillTransfer,
  faImage,
  faVideo,
  faMagnifyingGlassDollar,
  faReceipt,
  faBox,
  faMoneyCheckDollar,
  faBagShopping,
  faStore,
  faShippingFast,
  faHome,
  faCalendarDays,
  faLocationCrosshairs,
  faCarRear,
  faAngleLeft,
  faAngleRight,
  faCreditCard,
  faTags,
  faCheckCircle,
  faTools,
  faTruckRampBox,
  faWarehouse,
  faTriangleExclamation,
  faX,
  faMagnifyingGlass,
  faScrewdriverWrench,
  faPaintRoller,
  faGear,
  faGears,
  faGlobe,
  faTag,
  faWrench,
  faShield,
  faTruckFast,
  faOilCan,
  faHandshake,
  faCopy,
  faCircleInfo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Icon = ({ iconName, onClick = undefined, className = undefined }) => {
  const icons = {
    facebook: faFacebookF,
    twitter: faTwitter,
    instagram: faInstagram,
    linkedIn: faLinkedinIn,
    whatsapp: faWhatsapp,
    xmark: faXmark,
    signOut: faSignOut,
    bell: faBell,
    pencil: faPencil,
    trash: faTrash,
    phone: faPhone,
    mapMarker: faMapMarkerAlt,
    chevronRight: faChevronRight,
    chevronLeft: faChevronLeft,
    chevronDown: faChevronDown,
    clock: faClock,
    user: faUser,
    car: faCar,
    cart: faShoppingCart,
    map: faMap,
    plus: faPlus,
    minus: faMinus,
    check: faCheck,
    download: faDownload,
    caretDown: faCaretDown,
    bars: faBars,
    exclaim: faExclamation,
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight,
    arrowUp: faArrowUp,
    hourGlass: faHourglass3,
    medal: faMedal,
    circle: faCircle,
    star: faStar,
    spinner: faSpinner,
    refund: faMoneyBillTransfer,
    image: faImage,
    video: faVideo,
    magnifyingDollar: faMagnifyingGlassDollar,
    shop: faStore,
    receipt: faReceipt,
    box: faBox,
    pay: faMoneyCheckDollar,
    shopbag: faBagShopping,
    delivery: faShippingFast,
    home: faHome,
    book: faCalendarDays,
    schedule: faCalendarDays,
    track: faLocationCrosshairs,
    offers: faTag,
    services: faCarRear,
    angleLeft: faAngleLeft,
    angleRight: faAngleRight,
    creditCard: faCreditCard,
    tags: faTags,
    checkCircle: faCheckCircle,
    tool: faTools,
    pickupTruck: faTruckRampBox,
    warehouse: faWarehouse,
    exclamation: faTriangleExclamation,
    cross: faX,
    search: faMagnifyingGlass,
    repair: faScrewdriverWrench,
    paint: faPaintRoller,
    servic: faGear,
    maintenance: faGears,
    globe: faGlobe,
    wrench: faWrench,
    shield: faShield,
    truckFast: faTruckFast,
    oilCan: faOilCan,
    collab: faXing,
    handShake: faHandshake,
    copy: faCopy,
    info: faCircleInfo,
    tiktok: faTiktok,
    snapchat: faSnapchat
  };

  return <FontAwesomeIcon icon={icons[iconName]} onClick={onClick} className={className} />;
};

export default Icon;
